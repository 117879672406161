import { FileService } from './../services/api/file';
import { ValidationRules } from 'aurelia-validation';
import { ProjectFile as BaseProjectFile } from '@wsb_dev/datafi-shared/lib/types/ProjectFile';
import { Filter } from '@wsb_dev/datafi-shared/lib/types/Filter';
import { ObjectWithFiles } from './ObjectWithFile';
import { LogManager } from 'aurelia-framework';

export interface ValidatedProjectFile extends BaseProjectFile { }

const log = LogManager.getLogger('dfp:files');

export class ValidatedProjectFile extends ObjectWithFiles {

    constructor(props: Partial<ValidatedProjectFile>, fileService: FileService) {
        super(fileService);
        props.__user_tags = props.user_tags?.split(',');
        props.__ai_tags = props.ai_tags?.split(',');
        Object.assign(this, props);
    }

    getThumbnail() {
        return this.getFile('thumbnail_id', 'thumbnail')
            .catch((e) => {
                log.warn('ProjectFile: thumbnail failed, loading full instead', e);
                return this.getFull();
            });
    }

    getFull() {
        return this.getFile('file_id', 'full');
    }

}

export const fileFilters: Filter[] = [
    { type: 'text', name: 'file_name', label: 'File Name' },
    { type: 'text', name: 'user_tags', label: 'Tags' },
    {
        type: 'object', label: 'Has GPS', name: 'hasGPS', fields: [
            {
                type: 'checkbox', name: 'hasGeometry', visibility: { edit: true }, options: [{ value: 'Yes', label: 'Yes' }],
            },
        ],
        toQuery(filter: Filter) {
            if (filter.value.hasGeometry) {
                if ((filter.value.hasGeometry[0] === 'Yes') || filter.value.hasGeometry === true) {
                    return {
                        $modify: {
                            isNull: ['geom', false],
                            toGeoJSON: true,
                        },
                    };
                }
            }
            else {
                return {};
            }
        },
        fromQuery(record: Record<string, any>) {
            if (!record.$modify) return { hasGeometry: [] };

            if (record.$modify.isNull) {
                return {
                    hasGeometry: ['Yes'],
                };
            } else {
                return { hasGeometry: [] };
            }

        },
    },
    { type: 'checkbox', name: 'file_type', label: 'File Type', options: [{ value: 'document', label: 'Document' }, { value: 'image', label: 'Image' }, { value: 'unknown', label: 'Other' }] },
    { type: 'date', name: 'updatedAt', label: 'Date Created' },
];

ValidationRules
    .ensure('file_name').displayName('File Name').required()
    .ensure('user_tags').displayName('Tags').required()
    .on(ValidatedProjectFile);
